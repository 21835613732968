<template>
  <div class="main">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card class="card">
        <div class="item">
          <h3>乌鲁木齐建设职业培训中心介绍</h3>
          <div class="editor-text">
            <p style="text-align: justify">
              <span style="font-size: 16px">&nbsp;
                <img width="100%" alt=""
                  src="https://www.wlmqcol.com/files/default/2020/03-24/133358684d5f021675.jpg" /></span>
            </p>

            <p>
              <span
                style="font-size: 16px">乌鲁木齐建设职业培训中心成立于1995年，是一家集教学、鉴定、实训于一体的综合性教育培训机构。本中心是自治区住房和城乡建设厅批准设立的二级建造师继续教育、三类人员继续教育、特种作业人员、施工现场专业人员、工人技能鉴定、工程质量检测试验人员、燃气从业人员教育培训机构，同时也是全疆施工安全&ldquo;三类人员&rdquo;新取证考点。</span>
            </p>

            <p>
              <span
                style="font-size: 16px">本中心是自治区人力资源和社会保障厅批准成立的乌鲁木齐市第二十职业技能鉴定所。是自治区人力和社会保障厅批准设立的工程类、经济类及其他委托专业的正高级、副高级继续教育培训基地，同时也是乌鲁木齐市人力资源和社会保障局批准设立的建筑类初级、中级、高级和市政类、水利类、环境保护类初级、中级专业技术人员继续教育基地；乌鲁木齐市专职安全生产管理人员继续教育点及乌鲁木齐市建委批准设立的建设行业特种作业人员新取证、复审培训基地。</span>
            </p>

            <p>
              <span
                style="font-size: 16px">除此之外，本中心还针对各相关企业开展自治区建设行业安全生产有关法律法规、安全生产管理人员、安全生产技术人员及安全生产警示教育的相关专业培训；建设行业企业内训等。</span>
            </p>

            <p>
              <span style="font-size: 16px"><img width="100%" alt=""
                  src="https://www.wlmqcol.com/files/default/2020/03-24/13384005f2f4134907.jpg" /></span>
            </p>

            <p>
              <span style="color: #b22222"><span style="font-size: 18px"><strong>硬件设施</strong></span></span>
            </p>

            <p>
              <span
                style="font-size: 16px">培训中心位于天山区西河坝前街111号，总建筑面积1980平方米，红光山培训基地建筑面积2800平方米，卡子湾实训基地建筑面积2540平方米，拥有电教室、微机教室、多媒体教室、实训操作场所，可同时容纳近2000人培训学习和实操考试。经过25年的发展与沉淀，本培训中心在建设培训行业内奠定了坚实基础，并不断改革和创新。2020年红光山培训基地10月底投入使用，软硬件全面升级，打造集科技与智能化于一体的现代化培训基地，也标志着本中心实现跨越式发展。</span>
            </p>

            <p>
              <img width="95%" alt="" src="https://www.wlmqcol.com/files/default/2021/02-07/102826a1cee6044465.jpg" />
            </p>

            <p style="text-align: center">
              <img width="95%" alt="" src="https://www.wlmqcol.com/files/default/2020/10-19/1831528419d0570335.jpg" />
            </p>

            <p>&nbsp;</p>

            <p>
              <span
                style="font-size: 16px">同时，本中心采用全套高清录播设备，实现互联网+数字化教育，以适应后疫情时代教育产业的变革。我们邀请名师在线，通过网站、小程序、钉钉等智能化、信息化手段，打造数字技术与教育教学深度融合的远程翻转课程，真正满足建设行业学员随时随地线上学习和培训的需求，为建设行业培养更多优秀人才。&nbsp;</span>
            </p>

            <p>
              <span style="color: #b22222"><span style="font-size: 18px"><strong>师资力量</strong></span></span>
            </p>

            <p>
              <span
                style="font-size: 16px">本中心有专业管理人员19人，专职和兼职教师30余人，特种作业人员教师及考评员均取得自治区安全协会培训合格后的师资证书；岗位八大员教师均为具有相关资格证书的建筑行业业内专家及相关院校教授；建筑技术工人教师及考评员均是有丰富理论及实践经验的建筑行业业内专家；建筑行业相关证书继续教育、职称继续教育及安全生产宣贯、企业内训的教师均邀请自治区各行业专家库专家授课。</span>
            </p>

            <p>
              <span
                style="font-size: 16px">&ldquo;筑巢引凤&rdquo;，打造人才集散地。雄厚的教学实力、众多的行业专家、现代化智能化的培训教学环境，吸引了众多建筑企业和学员前来我中心参加培训学习。</span>
            </p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>
              <span
                style="font-size: 16px">2018、2019年为贯彻创新发展新思路，我中心除在乌鲁木齐市开展相应培训外，应地方企业邀请及主管部门批准，先后在和田地区、喀什地区、阿克苏地区、哈密市、伊犁州、塔城地区、克拉玛依市、博尔塔拉蒙古自治州、奇台县等地开展了建筑行业相关培训工作。</span>
            </p>

            <p style="text-align: center">
              <img alt="" src="https://www.wlmqcol.com/files/default/2020/10-19/184510642707324071.png" />
            </p>

            <p style="text-align: center">
              <img alt="" src="https://www.wlmqcol.com/files/default/2020/10-19/1906273b21b5774773.png" />
            </p>

            <p style="text-align: center">
              <img alt="" src="https://www.wlmqcol.com/files/default/2020/10-19/185932471bb5929428.png" />
            </p>

            <p>&nbsp;</p>

            <p style="text-align: center">
              <img alt="" src="https://www.wlmqcol.com/files/default/2020/10-19/1859462e97e7859979.png" />
            </p>

            <p>&nbsp;</p>

            <p>
              <span
                style="font-size: 16px">2017年本中心共计培训人数为7260人，2018年共计培训人数为15899人，2019年共计培训人数为23605人，近三年累计培训人数为46764人。</span>
            </p>

            <p>
              <span
                style="font-size: 16px">中心具有健全的教学管理、考务管理、财务管理、各岗位职责管理、学员管理及教学档案归档管理等相关制度，是一家集教学、鉴定、实操于一体的综合性教育培训基地。</span>
            </p>

            <p>
              <span style="color: #b22222"><span style="font-size: 18px"><strong>所获荣誉</strong></span></span>
            </p>

            <p>
              <span
                style="font-size: 16px">热心公益、回报社会、为建设行业培养更多精英和优秀人才，是乌鲁木齐建设职业培训中心不变的初心。我们曾多次参与社会公益活动，获得社会各界和相关领导部门的高度赞誉。</span>
            </p>

            <p>
              <span style="font-size: 16px">被乌鲁木齐市民政局评为&ldquo;中国社会组织评估AAAAA级&rdquo;单位</span>
            </p>

            <p>
              <span style="font-size: 16px">2018、2019年被天山区民政局授予&ldquo;优秀社会组织&rdquo;荣誉称号</span>
            </p>

            <p>&nbsp;</p>

            <p>
              <span style="color: #b22222"><span style="font-size: 18px"><strong>未来愿景</strong></span></span>
            </p>

            <p>
              <span
                style="font-size: 16px">不忘初心，牢记使命。办学25年来，乌鲁木齐建设职业培训中心始终坚持&ldquo;立足行业、服务企业、匠心育人&rdquo;的办学宗旨，致力于为自治区建设行业培养和输送专业技术人才。</span>
            </p>

            <p>
              <span
                style="font-size: 16px">25载风雨兼程、25载励精图治，乌鲁木齐建设职业培训中心已成为社会经济发展的需要、培养专业技能人才的主阵地。未来，我们将砥砺前行，不断整合优势资源，以行业需求为导向，以客户价值为中心，为行业搭建全方位立体性综合服务平台！</span>
            </p>
            <div id="map">
              <div style="text-align:left;font-weight: bold;margin:20px 0 20px">乌鲁木齐建设职业培训中心地址:</div>
              <div style="width:1130px;height: 500px;" id="container">
                <baidu-map  :center="{lng: 87.61697, lat: 43.79819 }" center2="乌鲁木齐市天山区西河坝前街111号" :zoom="zoom" class="bm-view">
                  <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                  <bm-marker :position="markerPoint" :dragging="true" @click="infoWindowOpen">
      <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
      <div>
        <div style="color:#b22222">乌鲁木齐建设职业培训中心</div>
        <div style="font-size: 14px; padding-top: 10px; color: #999;">地址:新疆维吾尔自治区乌鲁木齐市天山区西河坝前街111号</div>
      </div>
      </bm-info-window>
    </bm-marker>
  </baidu-map>

              </div>

              <!-- <iframe src="https://t1.www.wlmqcol.com/baidu-map.html" width="1130" height="500" vspace="-500" hspace="-500"></iframe> -->
            </div>
            <p>&nbsp;</p>
          </div>
        </div>
      </el-card>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
export default {
  name: "Aboutus",
  components: {
    HeaderT,
    Nav2,
    Footer,
  },
  data () {
    return {
      markerPoint: {lng: 87.61697, lat: 43.79819 },
      show: true,
      zoom: 17


    }
  },
  mounted () {
    if (this.$route.query.param) {
      setTimeout(() => {
        document.getElementById("map").scrollIntoView();
        // var map = new BMap.Map("container");          // 创建地图实例  
        // var point = new BMap.Point(116.404, 39.915);  // 创建点坐标  
        // map.centerAndZoom(point, 15);                 // 初始化地图，设置中心点坐标和地图级别  
      }, 100)
    }
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.bm-view{
  width:1130px;height: 500px;
}
.main {
  height: 100%;
  background-color: #f5f5f5;

  .cont {
    width: 1170px;
    margin: 20px auto;

    .card {
      margin-top: 20px;
      box-shadow: 0 0 1px #eee;
      border: none;

      h3 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.5;
      }

      .editor-text {
        p {
          line-height: 28px;
          text-align: left;
          color: #333;
          text-indent: 2em;

          img {}
        }
      }
    }
  }
}
</style>